.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.Toastify{
  position: absolute;
    z-index: 1000;
    width: 365px;
    top: 71px;
    right:0;


  }

.Toastify__toast{
  width: 100%;
}
.Toastify__toast--default{
border: 1px solid #99D9B0 !important;
background: #F0FDF4 !important;
border-radius:6px !important;
top:10px !important;
padding:10px!important;
  }
/*   
.Toastify__toast-container{
top:0 !important;
right:0 !important;
transform: translate(0, -59px) !important;
} */

.feedback-checkbox label {
  display: none;
}

.tap-highlighter-none {
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

@media (max-width: 650px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 651px) {
  .nps-scrollbar::-webkit-scrollbar {
    display: block !important;
  }
  .nps-scrollbar::-webkit-scrollbar:horizontal {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #C1C1C1 !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #F1F1F1 !important;
  }
}

@media (min-width: 768px) {
  .nps-desktop-modal {
    max-height: fit-content;
    max-width: 672px;
    margin: auto;
    border-radius: 8px;
  }
  .nps-desktop-modal .nps-desktop-modal-content {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

.Toastify__toast-theme--light{
  /* position:absolute; */
}

@media (min-width: 650px) {
  .Toastify__toast-container {
    top: 0 !important;
    right: 0 !important;
    /* transform: translate(0, -59px) !important; */
  }
}